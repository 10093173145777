import Typography from "@mui/material/Typography";
import * as React from "react";

export default function SubHeader({ sx = {}, ...props }) {
  return (
    <Typography
      component="h6"
      color="secondary.dark"
      variant="h6"
      sx={{ mb: 2, ...sx }}
      {...props}
    />
  );
}
