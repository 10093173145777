import Box from "@mui/material/Box";
import BlogLayout from "src/components/BlogLayout";
import Overline from "src/components/Overline";
import Paragraph from "src/components/Paragraph";
import SubHeader from "src/components/SubHeader";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
export default function Day1FundPage() {
  return (
    <BlogLayout slug="day-1-fund">
      <SubHeader>
        East Oakland Community Project Receives $2.5 Million Bezos Day 1
        Families Fund Grant to End Homelessness
      </SubHeader>
      <Overline>
        Day 1 Families Fund grants $105.9 million to 42 nonprofits helping
        families gain housing support and stability
      </Overline>
      <Paragraph>
        <b>OAKLAND, Calif. – (December 9, 2020):</b> The East Oakland Community
        Project (EOCP), a community-based, homelessness services provider in
        Alameda County, today announced that it has been selected to receive a
        $2.5 million grant from the Day 1 Families Fund. Launched in 2018 by
        Amazon founder and CEO Jeff Bezos, the Day 1 Families Fund issues annual
        leadership awards to organizations and civic groups doing compassionate,
        needle-moving work to provide shelter and hunger support to address the
        immediate needs of young families.
      </Paragraph>
      <Paragraph>
        “We are very thankful to have been selected by the Day 1 Families Fund
        to help stabilize families experiencing homelessness,” said Wendy
        Jackson, Executive Director of EOCP.{" "}
      </Paragraph>
      <Paragraph>
        This one-time grant, awarded to organizations doing meaningful work to
        connect families with shelter and support, will allow EOCP to provide
        nine months of aftercare support after families have been placed in
        permanent housing. This helps to ensure that families can stabilize in
        their new communities.{" "}
      </Paragraph>

      <Box
        sx={{
          backgroundColor: "green.50",
        }}
        p={3}
        pb={1}
        my={3}
      >
        <Paragraph variant="body2">
          EOCP is one of 42 nonprofits across the U.S. to receive the third
          annual Day 1 Families Fund grants, as part of a continuing commitment
          by the Day 1 Families Fund to help end homelessness for families. The
          Day 1 Families Fund issued a total of $105.9 million in grants this
          year. To select these organizations, the fund worked with an advisory
          board of homelessness advocates and leaders whose expertise spans
          housing justice, racial equity, direct services, homelessness policy,
          equity for Native American communities and anti-poverty work. This
          year, the grant recipients from around the country include: Anchorage
          Coalition to End Homelessness; The Cathedral Center, Inc.; Catholic
          Charities of Acadiana; Coalition for Homelessness Intervention &
          Prevention; Coalition for the Homeless of Central Florida; Community
          Action Council for Lexington-Fayette, Scott, Madison, Bourbon,
          Harrison and Nicholas County, Inc.; Congreso de Latinos Unidos;
          Coalition on Homelessness and Housing in Ohio; Denver Indian Family
          Resource Center; East Los Angeles Women’s Center; East Oakland
          Community Project; Facing Forward to End Homelessness; Families
          Together; Family Life Center; Friendship Place; HELP of Southern
          Nevada; The Homeless Families Foundation; Homeless Outreach Program
          Integrated Care System; HOPE Atlanta; House of Ruth; Housing Matters;
          Housing Up; Kahumana; MAHUBE-OTWA Community Action Partnership, Inc.;
          Metro Denver Homeless Initiative; MUST Ministries; National Center for
          Children and Families; Native American Youth and Family Center; North
          Carolina Coalition to End Homelessness; One80 Place; Poverello House;
          Rainbow Services; Refugee Women’s Alliance; Safe Haven Family Shelter;
          Samaritan House; Solid Ground; St. Vincent de Paul CARES; Su Casa –
          Ending Domestic Violence; The Salvation Army Austin Area Command; Time
          for Change Foundation; United American Indian Involvement, Inc.; and
          WestCare California.{" "}
        </Paragraph>
      </Box>

      <Paragraph>
        “The ability for EOCP to provide aftercare support to those families in
        most critical need cannot be understated. We want families to embrace a
        future free of homelessness so their children can thrive,” Jackson
        continued.
      </Paragraph>
      <Paragraph>
        The Bezos Day One Fund was launched in 2018 with a commitment of $2
        billion and a focus on two areas: funding existing nonprofits that help
        homeless families, and creating a network of new, nonprofit tier-one
        preschools in low-income communities. The Day 1 Families Fund issues
        annual leadership awards to organizations and civic groups doing
        compassionate, needle-moving work to provide shelter and hunger support
        to address the immediate needs of young families. The vision statement
        comes from the inspiring Mary’s Place in Seattle: no child sleeps
        outside. For more information, visit{" "}
        <Link href="https://www.BezosDayOneFund.org/Day1FamiliesFund">
          www.BezosDayOneFund.org/Day1FamiliesFund
        </Link>
        .
      </Paragraph>
    </BlogLayout>
  );
}
